import { useHead as _useHead } from '@vueuse/head'
import { merge } from 'lodash-es'

export const defaultHead = {
  title: '华夏源细胞集团',
  meta: [
    {
      name: 'description',
      content:
        '华夏源细胞工程集团股份有限公司，注册资本3.975亿元，上海总部建成核心生物实验空间逾1.5万平米。公司以iPSC（诱导多能干细胞）重编程与多向分化、3D生物打印组织工程、类器官与器官芯片等多项细胞工程前沿技术为支撑，致力于从新药研发、科研服务、个体化精准医疗等多维度提供细胞层面解决方案。公司核心研发管线已成功获得国家药品监督管理局药品审评中心（CDE）批准进入临床阶段（IND）。华夏源力争成为具有全球性、前瞻性、影响性的生物行业领导者，推动细胞科技惠及普罗大众。'
    },
    {
      name: 'keywords',
      content: '免疫细胞存储,细胞治疗,细胞抗衰,华夏源生命库,干细胞技术'
    }
  ]
}

interface HeadMethods {
  title?: string
  meta?: {
    name: string
    content: string
  }[]
}

/**
 * useHead 合并配置项，使用lodash的merge来合并默认head配置
 * @param customHead
 */
export function useHead(customHead?: HeadMethods) {
  const trueHead = merge(defaultHead, customHead)
  // console.log('trueHead: ', trueHead)
  _useHead(trueHead)
}
