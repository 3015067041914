<template>
  <div class="header absolute top-0 left-0 z-100">
    <van-sticky>
      <div
        :class="
          [
            'header-nav h-44px w-screen absolute z-10 flex justify-between overflow-hidden ',
            route.path.split('/').length > 2 || changeBg ? 'other-style ' : 'main-style ',
            state ? 'active' : ''
          ].join('')
        "
      >
        <!-- <img
          v-if="!state"
          class="ml-16px w-74px h-34px mt-5px"
          :src="`${$imgBasePath}/img/logo${
            route.path.split('/').length < 3 &&
            ['/tec-plat', '/snc-life', '/join'].includes(route.path) &&
            !changeBg
              ? '-2'
              : ''
          }.png `"
          alt=""
          @click.stop="showChildNav({ link: '/' })"
        /> -->
        <template v-if="!state">
          <svg
            v-if="
              !(
                route.path.split('/').length < 3 &&
                ['/tec-plat', '/snc-life', '/join'].includes(route.path) &&
                !changeBg
              )
            "
            class="ml-16px w-74px h-34px mt-5px"
            @click.stop="showChildNav({ link: '/' })"
            viewBox="0 0 72 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M43.8027 17.1371C43.836 16.519 43.8194 15.8842 43.7694 15.2661C43.7027 14.7817 43.6028 14.2805 43.3029 13.8963C42.7364 13.1613 41.6701 13.0944 40.7371 13.0777C39.5376 13.061 37.2717 13.0777 36.8219 13.0777C36.3887 13.0777 35.9388 13.0109 35.7556 12.8606C35.5723 12.7102 35.389 12.6434 35.389 11.8416C35.389 10.9729 36.1888 10.7891 36.8219 10.8058C37.4883 10.8058 39.5376 10.8058 39.5376 10.8058C40.304 10.8058 40.5539 10.9729 40.5205 11.9919H43.4528C43.4028 11.0397 43.786 9.51953 42.1533 8.91815C41.9867 8.85133 41.8201 8.81792 41.6368 8.81792C39.6542 8.70098 35.1058 8.80121 35.1058 8.80121C34.2728 8.80121 33.3731 9.01838 32.9566 9.70329C32.4734 10.5218 32.6067 11.5576 32.6067 12.5933C32.6067 13.629 32.74 14.3139 33.3897 14.8151C33.8729 15.1826 35.1225 15.1993 35.1225 15.1993H39.8874C40.0874 15.1826 40.2706 15.216 40.4539 15.2995C41.0037 15.6002 40.9037 16.803 40.7705 17.0202C40.5205 17.4378 39.9541 17.4879 39.4709 17.4879C38.5879 17.4879 37.6882 17.5046 36.8052 17.5046C35.6389 17.5046 35.539 16.9366 35.539 16.3018L32.6567 16.3185C32.6567 17.254 32.5734 18.3232 33.2565 18.9412C33.6063 19.2586 34.0895 19.3923 34.5727 19.4758C35.2058 19.576 35.8389 19.6095 36.472 19.576H40.8538C43.2029 19.5426 43.8027 18.1394 43.8027 17.1371Z"
              fill="#18A565"
            />
            <path
              d="M57.8475 19.576V8.76779H54.9986V16.3352L49.917 8.76779L45.8851 8.73438V19.7264L48.7341 18.8076V12.5097L53.4991 19.576H57.8475Z"
              fill="#18A565"
            />
            <path
              d="M70.8096 18.44C71.2428 17.7551 71.1095 16.5857 71.1095 15.5834H68.5771C68.3272 15.5834 68.1272 15.7839 68.1106 16.0178C68.0939 16.686 67.5608 17.2205 66.8943 17.2038C66.8777 17.2038 66.8777 17.2038 66.861 17.2038H64.1453C63.7455 17.2038 63.2623 17.0869 63.079 16.8363C62.8458 16.4855 62.7292 16.0679 62.7458 15.6502C62.7458 14.6312 62.7458 13.6122 62.7458 12.5765C62.7458 12.1087 62.7958 11.6076 63.129 11.2902C63.3789 11.0897 63.6955 10.9895 64.012 10.9728H66.6611C67.3442 10.9728 67.794 11.4405 67.844 11.9751C67.8773 12.209 68.0773 12.376 68.3105 12.376H70.9762C70.9762 11.2568 71.0929 10.0206 70.2931 9.26887C69.6767 8.66748 68.2772 8.66748 67.6607 8.66748L62.1294 8.7343C60.9965 8.7343 59.8302 9.83684 59.8302 11.4572C59.8302 12.7101 59.8302 16.7695 59.8302 16.7695C59.8302 17.8386 60.6299 19.4256 62.0961 19.4256H68.8103C69.61 19.459 70.3431 19.0748 70.8096 18.44Z"
              fill="#18A565"
            />
            <path
              d="M10.0335 11.9918C10.3001 10.4884 9.30046 9.06844 7.81765 8.78446C6.75136 8.6007 5.83502 8.96821 5.08529 9.83688C4.68543 10.2879 4.06899 10.4383 3.51918 10.2545C2.81943 9.97052 2.6195 9.11856 1.71982 8.98492C0.92011 8.85128 0.170377 9.36914 0.0204302 10.171C-0.112856 10.9728 0.420288 11.7246 1.20334 11.8749C2.10302 12.0754 2.58618 11.3403 3.33592 11.3236C3.76909 11.3069 4.46884 11.6076 4.66877 12.2758C4.96867 13.2948 5.8017 14.0466 6.83467 14.247C8.31747 14.4976 9.76696 13.4953 10.0335 11.9918Z"
              fill="#212723"
            />
            <path
              d="M26.9774 18.2897C26.8274 18.1394 26.6608 18.0057 26.4775 17.9055C25.7611 17.538 24.7781 17.6549 24.295 17.0201C25.1447 15.1325 25.0447 12.9608 24.0117 11.1733C22.9954 9.41929 21.1294 8.1664 19.1135 7.99935C17.8306 7.89912 16.4478 8.21652 15.2649 7.69866C14.4485 7.33114 13.8154 6.62953 13.5655 5.76086C13.2822 4.77526 13.5321 3.65601 13.2989 2.6537C12.8491 0.799428 10.9831 -0.353228 9.13372 0.0978115C7.41766 0.51544 6.28473 2.15255 6.51798 3.90659C6.63461 4.9256 7.21773 5.82768 8.10075 6.37895C9.33364 7.11398 10.8331 6.74646 12.1826 8.23322C13.149 9.28565 13.0323 10.4717 12.2993 12.5432C11.6828 14.2638 12.1493 15.6837 10.4999 16.5023C9.51691 16.9867 8.31734 16.3519 7.23439 16.4355C5.91819 16.5524 4.95187 17.7218 5.0685 19.0415C5.18512 20.3612 6.35137 21.3301 7.66757 21.2131C8.28402 21.163 8.86714 20.8623 9.28366 20.3779C9.6502 19.9435 9.86679 19.3756 10.1833 18.9078C10.6665 18.2062 11.5162 17.6716 12.3492 17.8387C13.0823 17.989 13.5988 18.6405 14.1486 19.1417C16.731 21.5305 20.7629 21.3802 23.1621 18.7909C23.3453 18.5904 23.5119 18.39 23.6619 18.1728C23.9784 18.7742 23.8951 19.5259 24.1617 20.1607C24.5616 21.0127 25.5779 21.3802 26.4276 20.9793C27.2773 20.5783 27.6438 19.5593 27.2439 18.7074C27.1773 18.5403 27.0773 18.4067 26.9774 18.2897Z"
              fill="#18A565"
            />
            <path
              d="M19.1302 7.98257C19.4968 8.01598 19.8466 8.0828 20.1965 8.18303C20.5464 7.99927 20.9296 7.94916 21.3128 8.03268C22.3124 8.24985 23.3787 8.19973 24.1784 7.448C25.2947 6.39558 25.3447 4.64154 24.295 3.52229C23.4787 2.65362 22.1958 2.40304 21.1128 2.9042C19.6634 3.60582 19.4301 4.97564 19.63 6.22853C19.73 6.91344 19.3968 7.53153 19.0303 7.74869C18.8137 7.86563 18.5804 7.94916 18.3472 7.96586C18.5971 7.96586 18.8636 7.96586 19.1302 7.98257Z"
              fill="#212723"
            />
            <path
              d="M1.65332 25.072L5.41864 24.0196V25.3894L4.40234 25.6734V28.8808H2.88621V26.091L1.65332 26.4251V25.072ZM5.68522 31.5202H1.66998V30.284H5.70188V29.1481H7.218V30.284H11.2166V31.5202H7.218V33.007H5.70188L5.68522 31.5202ZM8.03438 28.8641C7.86777 28.8641 7.71783 28.8307 7.56788 28.7639C7.41793 28.697 7.30131 28.5968 7.18468 28.4632C7.06806 28.3295 6.98475 28.1959 6.93477 28.0288C6.86813 27.8618 6.83481 27.678 6.83481 27.4943V26.9263L5.31868 27.1769V25.7903L6.83481 25.5398V23.9695H8.35093V25.2892L11.2166 24.8047V26.1913L8.35093 26.6757V27.227C8.35093 27.3439 8.38425 27.4275 8.4509 27.511C8.51754 27.5778 8.60084 27.6279 8.68415 27.6279H11.1999V28.8641H8.03438Z"
              fill="#18A565"
            />
            <path
              d="M15.4983 24.9217H11.6497V23.9695H21.2462V24.9217H17.131L16.9478 25.2892H19.5468H20.913V25.5398V26.1077V28.1625C20.913 28.3295 20.8797 28.4799 20.8297 28.6302C20.7797 28.7806 20.6964 28.8975 20.6131 29.0144C20.5298 29.1314 20.4132 29.2149 20.2799 29.265C20.1466 29.3318 20.0133 29.3652 19.8801 29.3652H19.5302H18.2306H14.0488L13.8989 29.6994H20.8797V30.6348C20.6631 30.852 20.3965 31.0859 20.08 31.3198C19.7801 31.5536 19.3969 31.7541 18.9304 31.9545C19.2803 31.988 19.6468 32.0047 20.03 32.0214C20.4132 32.0381 20.8131 32.0381 21.2129 32.0381V33.0738C20.2133 33.0571 19.3303 33.007 18.5639 32.9402C17.7975 32.8733 17.0977 32.7731 16.4813 32.6562C15.8648 32.7731 15.1651 32.8733 14.3654 32.9402C13.5823 33.007 12.666 33.0571 11.6497 33.0738V32.0381C12.0828 32.0381 12.4994 32.0381 12.8992 32.0214C13.2991 32.0047 13.6823 31.988 14.0488 31.9545C13.6489 31.7875 13.3324 31.6204 13.0492 31.42C12.7826 31.2362 12.5327 31.0525 12.3327 30.852H14.382C14.9651 31.1694 15.6482 31.42 16.4313 31.587C17.3809 31.3699 18.1807 31.0525 18.8638 30.6181H13.4657H12.0162L12.566 29.3652H11.9496V25.3059H11.9662H13.3157H15.315L15.4983 24.9217ZM18.3806 26.091H13.3324V26.4251H18.264H19.5302V26.091H18.3806ZM19.5468 27.0933H19.3469H13.3324V27.4275H18.2973H19.5302L19.5468 27.0933ZM19.2303 28.4632C19.3136 28.4632 19.3802 28.4298 19.4469 28.3629C19.5135 28.2961 19.5468 28.2126 19.5468 28.1124V28.0957H19.3802H13.3324V28.4632H19.2303Z"
              fill="#18A565"
            />
            <path
              d="M21.6796 26.0409H22.4126L21.6796 23.9695H23.2957L24.312 26.7759H23.5789L24.312 28.8474H22.6959L21.6796 26.0409ZM21.6796 33.007L22.6792 29.4321H24.2953L23.279 33.007H21.6796ZM31.2262 25.0386H25.8281V31.7708L25.3449 33.007H23.8621L24.3453 31.7708V23.9695H31.2262V25.0386ZM26.178 30.3341H27.4275L27.111 32.9736H25.8614L26.178 30.3341ZM29.2935 31.6372C29.2935 31.8376 29.2602 32.0047 29.1936 32.1717C29.1269 32.3388 29.0436 32.4724 28.9436 32.606C28.827 32.7397 28.7104 32.8232 28.5604 32.9067C28.4105 32.9736 28.2606 33.007 28.0939 33.007H27.1776L27.5441 32.1383H27.6108C27.6608 32.1383 27.7107 32.1216 27.7607 32.0715C27.7941 32.0214 27.8274 31.9713 27.8274 31.9044V29.9833H26.0947V25.7736H27.7941L27.9273 25.3727H29.5768L29.4435 25.7736H31.0596V28.6803C31.0596 29.0646 30.9596 29.382 30.7597 29.6158C30.5597 29.8664 30.2932 29.9833 29.9766 29.9833H29.3268V31.6372H29.2935ZM27.5275 26.659V27.4442H29.5268V26.659H27.5275ZM27.5275 29.0813H29.1936C29.2935 29.0813 29.3602 29.0479 29.4268 28.9643C29.4934 28.8808 29.5268 28.7973 29.5268 28.6803V28.246H27.5275V29.0813ZM29.9599 32.9736L29.6601 30.3341H30.9096L31.2095 32.9736H29.9599Z"
              fill="#18A565"
            />
            <path
              d="M31.6757 30.9523L32.8752 28.1124H31.6757L33.4417 23.9862H35.0078L33.8082 26.8261H35.0078L33.7083 29.8664H34.9245V30.969H33.2418H32.1588H31.6757V30.9523ZM31.6757 31.8376L34.9245 31.4534V32.6395L31.6757 33.007V31.8376ZM41.2223 23.9862V25.0887V27.7282V28.8474V31.6372C41.2223 31.8376 41.1889 32.0047 41.1223 32.1717C41.0556 32.3388 40.9723 32.4891 40.8557 32.6061C40.7391 32.723 40.6225 32.8232 40.4725 32.8901C40.3226 32.9569 40.1726 32.9903 40.0227 32.9903H35.2577V25.0887V23.9862H41.2223ZM36.6739 27.7282H37.5402V25.0887H36.6739V27.7282ZM36.6739 31.8877H37.5402V28.8474H36.6739V31.8877ZM39.7728 25.0887H38.9064V27.7282H39.7728V25.0887ZM39.7728 28.8474H38.9064V31.8877H39.4396C39.5395 31.8877 39.6062 31.8543 39.6728 31.7708C39.7395 31.704 39.7728 31.6038 39.7728 31.4868V28.8474Z"
              fill="#18A565"
            />
            <path
              d="M41.7223 33.0237L41.9222 31.8042V30.3007V29.2149V27.6947V26.6089V25.072V23.9695H43.1385H43.8382H45.0878H45.1211V32.0047C45.1211 32.1884 45.1044 32.3388 45.0544 32.4724C45.0045 32.606 44.9545 32.7063 44.8878 32.7898C44.8212 32.8733 44.7379 32.9234 44.6546 32.9569C44.5713 32.9903 44.488 33.007 44.4213 33.007H43.2551L43.555 32.1717H43.7216C43.7716 32.1717 43.8215 32.155 43.8382 32.0882C43.8382 32.0548 43.8549 32.0381 43.8549 32.0047V30.284H43.1385V31.7875L42.9052 33.007H41.7223V33.0237ZM43.1385 26.6089H43.8382V25.072H43.1385V26.6089ZM43.1385 29.2149H43.8382V27.6947H43.1385V29.2149ZM47.6702 23.9862L47.3869 24.7212H51.069V30.0168C51.069 30.1838 51.0356 30.3341 50.9857 30.4678C50.9357 30.6014 50.8524 30.7351 50.7691 30.8353C50.6858 30.9522 50.5691 31.0358 50.4525 31.0859C50.3359 31.1527 50.2026 31.1694 50.0527 31.1694H48.22L48.6198 30.117H49.4862C49.5695 30.117 49.6361 30.0836 49.6861 30.0168C49.7361 29.9499 49.7694 29.8664 49.7694 29.7829V25.7736H45.4376L46.1207 23.9862H47.6702ZM46.5706 32.9402C46.4373 32.9402 46.304 32.9067 46.1874 32.8399C46.0707 32.7731 45.9541 32.6896 45.8708 32.5893C45.7875 32.4891 45.7042 32.3555 45.6542 32.2218C45.6042 32.0882 45.5709 31.9211 45.5709 31.7541V30.0168V28.9309V27.3439V26.4084V26.2581H49.4529V28.8641C49.4529 29.0311 49.4195 29.1815 49.3696 29.3318C49.3196 29.4822 49.2363 29.5991 49.153 29.6994C49.0697 29.7996 48.953 29.8831 48.8198 29.9499C48.6865 30.0167 48.5532 30.0502 48.4199 30.0502H46.9704V31.587C46.9704 31.6873 47.0037 31.7541 47.0537 31.8209C47.1037 31.8877 47.187 31.9211 47.2537 31.9211H51.1856L50.7691 32.9736H46.5706V32.9402ZM46.9704 28.9309H48.02C48.07 28.9309 48.12 28.9142 48.1533 28.8808C48.1867 28.8474 48.2033 28.7973 48.2033 28.7472V27.3439H46.9704V28.9309Z"
              fill="#18A565"
            />
            <path
              d="M54.801 23.9862L53.7681 25.2725H54.0346V29.4655H52.5352V25.5899H51.7021L52.985 23.9862H54.801ZM57.2168 30.1838H61.2321V31.2697H59.716L61.2321 33.007H59.3494L57.8999 31.2697H57.2335V33.007H55.7174V31.2697H55.0343L53.5848 33.007H51.7021L53.2183 31.2697H51.7021V30.1838H55.734V29.8497H57.2502V30.1838H57.2168ZM58.6997 24.5876H61.2487V25.4897H58.7163V25.924H61.2487V26.8094H58.7163V27.2437H61.2487V28.1291H58.7163V28.5634H61.2487V29.4488H54.6511V28.5634H57.2002V28.1291H54.6511V27.2604H57.2002V26.8261H54.6511V25.924H57.2002V25.4897H54.6511V24.5876H57.1835L57.0336 23.9862H58.533L58.6997 24.5876Z"
              fill="#18A565"
            />
            <path
              d="M63.0811 33.0237H61.6816V23.9695H71.2282V31.6372C71.2282 31.8376 71.1949 32.0047 71.1283 32.1717C71.0616 32.3388 70.9783 32.4891 70.8784 32.606C70.7617 32.7397 70.6451 32.8232 70.4952 32.9067C70.3452 32.9736 70.1953 33.007 70.0287 33.007H69.2456H68.8124L63.0811 33.0237ZM63.0811 31.7875H69.2623H69.4955C69.5955 31.7875 69.6788 31.7541 69.7454 31.6706C69.8121 31.587 69.8454 31.5035 69.8454 31.3866V25.2057H63.0978V31.7875H63.0811ZM68.5459 27.3606V29.8163C68.5459 30.0167 68.5125 30.1838 68.4459 30.3509C68.3793 30.5179 68.296 30.6683 68.196 30.7852C68.0794 30.9188 67.9627 31.0024 67.8128 31.0859C67.6628 31.1527 67.5129 31.1861 67.3463 31.1861H66.2633L66.7298 30.0168H66.8798C66.9798 30.0168 67.0631 29.9833 67.1297 29.8998C67.1963 29.8163 67.2297 29.7328 67.2297 29.6158V27.3272H66.4633L64.8972 31.1694H63.5476L65.1138 27.3272H63.5476V26.1579H67.2297V25.5899H68.5459V26.1913H69.3789V27.3606H68.5459Z"
              fill="#18A565"
            />
          </svg>
          <svg
            v-else
            class="ml-16px w-74px h-34px mt-5px"
            @click.stop="showChildNav({ link: '/' })"
            viewBox="0 0 72 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M43.8037 17.1375C43.837 16.5194 43.8204 15.8846 43.7704 15.2665C43.7037 14.782 43.6038 14.2808 43.3039 13.8966C42.7374 13.1616 41.6711 13.0948 40.7381 13.078C39.5385 13.0613 37.2725 13.078 36.8227 13.078C36.3895 13.078 35.9397 13.0112 35.7564 12.8609C35.5731 12.7105 35.3898 12.6437 35.3898 11.8418C35.3898 10.9731 36.1896 10.7894 36.8227 10.8061C37.4891 10.8061 39.5385 10.8061 39.5385 10.8061C40.3049 10.8061 40.5548 10.9731 40.5215 11.9922H43.4538C43.4038 11.04 43.787 9.51976 42.1542 8.91837C41.9876 8.85154 41.821 8.81813 41.6378 8.81813C39.6551 8.70119 35.1066 8.80143 35.1066 8.80143C34.2735 8.80143 33.3738 9.0186 32.9573 9.70353C32.4741 10.5221 32.6074 11.5578 32.6074 12.5936C32.6074 13.6293 32.7407 14.3143 33.3905 14.8154C33.8737 15.1829 35.1233 15.1997 35.1233 15.1997H39.8883C40.0883 15.1829 40.2715 15.2164 40.4548 15.2999C41.0046 15.6006 40.9047 16.8034 40.7714 17.0206C40.5215 17.4382 39.955 17.4883 39.4718 17.4883C38.5888 17.4883 37.6891 17.505 36.806 17.505C35.6398 17.505 35.5398 16.937 35.5398 16.3022L32.6574 16.3189C32.6574 17.2544 32.5741 18.3236 33.2572 18.9417C33.6071 19.2591 34.0903 19.3927 34.5734 19.4763C35.2066 19.5765 35.8397 19.6099 36.4728 19.5765H40.8547C43.2039 19.5431 43.8037 18.1398 43.8037 17.1375Z"
              fill="#74FFC0"
            />
            <path
              d="M57.8489 19.5765V8.76797H54.9999V16.3356L49.9182 8.76797L45.8862 8.73456V19.7268L48.7353 18.808V12.51L53.5004 19.5765H57.8489Z"
              fill="#74FFC0"
            />
            <path
              d="M70.8112 18.4405C71.2444 17.7556 71.1111 16.5862 71.1111 15.5839H68.5786C68.3287 15.5839 68.1288 15.7843 68.1121 16.0182C68.0955 16.6864 67.5623 17.221 66.8959 17.2043C66.8792 17.2043 66.8792 17.2043 66.8625 17.2043H64.1468C63.7469 17.2043 63.2637 17.0874 63.0805 16.8368C62.8472 16.486 62.7306 16.0683 62.7472 15.6507C62.7472 14.6316 62.7472 13.6126 62.7472 12.5769C62.7472 12.1091 62.7972 11.6079 63.1304 11.2905C63.3804 11.0901 63.6969 10.9898 64.0135 10.9731H66.6626C67.3457 10.9731 67.7956 11.4409 67.8455 11.9755C67.8789 12.2093 68.0788 12.3764 68.3121 12.3764H70.9778C70.9778 11.2571 71.0945 10.0209 70.2947 9.26916C69.6783 8.66776 68.2787 8.66776 67.6623 8.66776L62.1308 8.73458C60.9978 8.73458 59.8315 9.83714 59.8315 11.4576C59.8315 12.7105 59.8315 16.7699 59.8315 16.7699C59.8315 17.8391 60.6313 19.4261 62.0975 19.4261H68.8119C69.6116 19.4595 70.3447 19.0753 70.8112 18.4405Z"
              fill="#74FFC0"
            />
            <path
              d="M10.0338 11.9922C10.3003 10.4887 9.30067 9.06869 7.81783 8.7847C6.75152 8.60094 5.83516 8.96846 5.08541 9.83715C4.68554 10.2882 4.06908 10.4385 3.51927 10.2548C2.8195 9.97079 2.61957 9.11881 1.71986 8.98517C0.920131 8.85152 0.170381 9.36939 0.0204307 10.1713C-0.112858 10.9731 0.420298 11.7249 1.20337 11.8752C2.10307 12.0757 2.58624 11.3406 3.33599 11.3239C3.76918 11.3072 4.46895 11.6079 4.66888 12.2762C4.96878 13.2952 5.80184 14.0469 6.83483 14.2474C8.31767 14.498 9.76718 13.4957 10.0338 11.9922Z"
              fill="white"
            />
            <path
              d="M26.978 18.2902C26.828 18.1398 26.6614 18.0062 26.4782 17.9059C25.7617 17.5384 24.7787 17.6553 24.2956 17.0205C25.1453 15.1328 25.0453 12.9611 24.0123 11.1736C22.996 9.41951 21.1299 8.16659 19.1139 7.99954C17.831 7.8993 16.4482 8.21671 15.2652 7.69884C14.4488 7.33132 13.8157 6.62968 13.5658 5.76099C13.2826 4.77537 13.5325 3.6561 13.2992 2.65376C12.8494 0.799447 10.9833 -0.353236 9.13393 0.0978138C7.41784 0.515453 6.28488 2.1526 6.51814 3.90668C6.63476 4.92572 7.2179 5.82782 8.10094 6.3791C9.33387 7.11414 10.8334 6.74662 12.1829 8.23342C13.1493 9.28587 13.0326 10.472 12.2995 12.5434C11.6831 14.2641 12.1496 15.6841 10.5001 16.5027C9.51714 16.9871 8.31754 16.3523 7.23457 16.4358C5.91834 16.5528 4.95199 17.7222 5.06862 19.0419C5.18525 20.3616 6.35153 21.3306 7.66775 21.2136C8.28422 21.1635 8.86736 20.8628 9.28388 20.3783C9.65043 19.944 9.86702 19.376 10.1836 18.9083C10.6668 18.2066 11.5165 17.6721 12.3495 17.8391C13.0826 17.9895 13.5991 18.641 14.1489 19.1421C16.7314 21.531 20.7634 21.3807 23.1626 18.7913C23.3459 18.5909 23.5125 18.3904 23.6624 18.1732C23.979 18.7746 23.8957 19.5264 24.1623 20.1612C24.5621 21.0132 25.5785 21.3807 26.4282 20.9798C27.2779 20.5788 27.6444 19.5598 27.2446 18.7078C27.1779 18.5407 27.078 18.4071 26.978 18.2902Z"
              fill="#74FFC0"
            />
            <path
              d="M19.1307 7.98281C19.4973 8.01622 19.8472 8.08305 20.197 8.18328C20.5469 7.99952 20.9301 7.9494 21.3133 8.03293C22.313 8.2501 23.3793 8.19999 24.179 7.44824C25.2953 6.39579 25.3453 4.6417 24.2957 3.52243C23.4793 2.65374 22.1964 2.40316 21.1134 2.90433C19.6639 3.60596 19.4306 4.97581 19.6306 6.22873C19.7305 6.91366 19.3973 7.53176 19.0308 7.74894C18.8142 7.86587 18.5809 7.9494 18.3477 7.96611C18.5976 7.96611 18.8642 7.96611 19.1307 7.98281Z"
              fill="white"
            />
            <path
              d="M1.65332 25.0726L5.41873 24.0202V25.39L4.40241 25.674V28.8815H2.88624V26.0917L1.65332 26.4258V25.0726ZM5.68531 31.521H1.66998V30.2848H5.70197V29.1488H7.21813V30.2848H11.2168V31.521H7.21813V33.0078H5.70197L5.68531 31.521ZM8.03453 28.8648C7.86792 28.8648 7.71797 28.8314 7.56802 28.7646C7.41807 28.6977 7.30144 28.5975 7.18481 28.4639C7.06818 28.3302 6.98488 28.1966 6.9349 28.0295C6.86825 27.8625 6.83493 27.6787 6.83493 27.4949V26.9269L5.31877 27.1775V25.791L6.83493 25.5404V23.9701H8.35109V25.2898L11.2168 24.8053V26.1919L8.35109 26.6764V27.2276C8.35109 27.3446 8.38441 27.4281 8.45106 27.5116C8.5177 27.5785 8.60101 27.6286 8.68431 27.6286H11.2001V28.8648H8.03453Z"
              fill="#74FFC0"
            />
            <path
              d="M15.4989 24.9223H11.6501V23.9701H21.247V24.9223H17.1317L16.9484 25.2898H19.5475H20.9137V25.5404V26.1084V28.1632C20.9137 28.3302 20.8804 28.4806 20.8304 28.6309C20.7804 28.7813 20.6971 28.8982 20.6138 29.0152C20.5305 29.1321 20.4139 29.2156 20.2806 29.2658C20.1473 29.3326 20.014 29.366 19.8807 29.366H19.5309H18.2313H14.0493L13.8994 29.7001H20.8804V30.6356C20.6638 30.8528 20.3972 31.0867 20.0807 31.3205C19.7808 31.5544 19.3976 31.7549 18.9311 31.9553C19.2809 31.9888 19.6475 32.0055 20.0307 32.0222C20.4139 32.0389 20.8138 32.0389 21.2136 32.0389V33.0746C20.214 33.0579 19.3309 33.0078 18.5645 32.941C17.7981 32.8742 17.0983 32.7739 16.4819 32.657C15.8654 32.7739 15.1656 32.8742 14.3659 32.941C13.5828 33.0078 12.6665 33.0579 11.6501 33.0746V32.0389C12.0833 32.0389 12.4999 32.0389 12.8997 32.0222C13.2996 32.0055 13.6828 31.9888 14.0493 31.9553C13.6495 31.7883 13.3329 31.6212 13.0497 31.4208C12.7831 31.237 12.5332 31.0532 12.3333 30.8528H14.3826C14.9657 31.1702 15.6488 31.4208 16.4319 31.5878C17.3816 31.3707 18.1813 31.0532 18.8644 30.6189H13.4662H12.0167L12.5665 29.366H11.95V25.3065H11.9667H13.3163H15.3156L15.4989 24.9223ZM18.3812 26.0917H13.3329V26.4258H18.2646H19.5309V26.0917H18.3812ZM19.5475 27.094H19.3476H13.3329V27.4281H18.2979H19.5309L19.5475 27.094ZM19.231 28.4639C19.3143 28.4639 19.3809 28.4305 19.4475 28.3637C19.5142 28.2968 19.5475 28.2133 19.5475 28.1131V28.0964H19.3809H13.3329V28.4639H19.231Z"
              fill="#74FFC0"
            />
            <path
              d="M21.6799 26.0416H22.413L21.6799 23.9701H23.2961L24.3124 26.7766H23.5793L24.3124 28.8481H22.6963L21.6799 26.0416ZM21.6799 33.0078L22.6796 29.4328H24.2957L23.2794 33.0078H21.6799ZM31.2268 25.0392H25.8285V31.7716L25.3454 33.0078H23.8625L24.3457 31.7716V23.9701H31.2268V25.0392ZM26.1784 30.3349H27.428L27.1115 32.9744H25.8619L26.1784 30.3349ZM29.2941 31.6379C29.2941 31.8384 29.2607 32.0055 29.1941 32.1725C29.1275 32.3396 29.0441 32.4732 28.9442 32.6069C28.8276 32.7405 28.7109 32.824 28.561 32.9076C28.411 32.9744 28.2611 33.0078 28.0945 33.0078H27.1781L27.5446 32.1391H27.6113C27.6613 32.1391 27.7113 32.1224 27.7612 32.0723C27.7946 32.0222 27.8279 31.9721 27.8279 31.9052V29.9841H26.0951V25.7743H27.7946L27.9278 25.3734H29.5773L29.444 25.7743H31.0601V28.6811C31.0601 29.0653 30.9602 29.3827 30.7602 29.6166C30.5603 29.8672 30.2937 29.9841 29.9772 29.9841H29.3274V31.6379H29.2941ZM27.528 26.6597V27.4448H29.5273V26.6597H27.528ZM27.528 29.082H29.1941C29.2941 29.082 29.3607 29.0486 29.4274 28.9651C29.494 28.8815 29.5273 28.798 29.5273 28.6811V28.2467H27.528V29.082ZM29.9605 32.9744L29.6606 30.3349H30.9102L31.2101 32.9744H29.9605Z"
              fill="#74FFC0"
            />
            <path
              d="M31.6765 30.953L32.8761 28.1131H31.6765L33.4426 23.9868H35.0087L33.8091 26.8267H35.0087L33.7092 29.8671H34.9254V30.9697H33.2427H32.1597H31.6765V30.953ZM31.6765 31.8384L34.9254 31.4542V32.6403L31.6765 33.0078V31.8384ZM41.2233 23.9868V25.0894V27.7288V28.8481V31.6379C41.2233 31.8384 41.19 32.0054 41.1234 32.1725C41.0567 32.3396 40.9734 32.4899 40.8568 32.6068C40.7402 32.7238 40.6235 32.824 40.4736 32.8908C40.3236 32.9577 40.1737 32.9911 40.0237 32.9911H35.2587V25.0894V23.9868H41.2233ZM36.6748 27.7288H37.5412V25.0894H36.6748V27.7288ZM36.6748 31.8885H37.5412V28.8481H36.6748V31.8885ZM39.7738 25.0894H38.9074V27.7288H39.7738V25.0894ZM39.7738 28.8481H38.9074V31.8885H39.4406C39.5406 31.8885 39.6072 31.8551 39.6739 31.7716C39.7405 31.7047 39.7738 31.6045 39.7738 31.4876V28.8481Z"
              fill="#74FFC0"
            />
            <path
              d="M41.7234 33.0245L41.9233 31.805V30.3015V29.2156V27.6954V26.6096V25.0727V23.9701H43.1396H43.8394H45.0889H45.1223V32.0055C45.1223 32.1892 45.1056 32.3396 45.0556 32.4732C45.0056 32.6069 44.9556 32.7071 44.889 32.7906C44.8224 32.8742 44.7391 32.9243 44.6557 32.9577C44.5724 32.9911 44.4891 33.0078 44.4225 33.0078H43.2562L43.5561 32.1725H43.7227C43.7727 32.1725 43.8227 32.1558 43.8394 32.089C43.8394 32.0556 43.856 32.0389 43.856 32.0055V30.2848H43.1396V31.7883L42.9063 33.0078H41.7234V33.0245ZM43.1396 26.6096H43.8394V25.0727H43.1396V26.6096ZM43.1396 29.2156H43.8394V27.6954H43.1396V29.2156ZM47.6714 23.9868L47.3882 24.7218H51.0703V30.0175C51.0703 30.1846 51.037 30.3349 50.987 30.4686C50.937 30.6022 50.8537 30.7358 50.7704 30.8361C50.6871 30.953 50.5704 31.0365 50.4538 31.0867C50.3372 31.1535 50.2039 31.1702 50.0539 31.1702H48.2212L48.6211 30.1177H49.4875C49.5708 30.1177 49.6374 30.0843 49.6874 30.0175C49.7374 29.9507 49.7707 29.8672 49.7707 29.7836V25.7743H45.4388L46.1219 23.9868H47.6714ZM46.5718 32.941C46.4385 32.941 46.3052 32.9076 46.1886 32.8407C46.0719 32.7739 45.9553 32.6904 45.872 32.5902C45.7887 32.4899 45.7054 32.3563 45.6554 32.2226C45.6054 32.089 45.5721 31.9219 45.5721 31.7549V30.0175V28.9316V27.3446V26.4091V26.2588H49.4541V28.8648C49.4541 29.0319 49.4208 29.1822 49.3708 29.3326C49.3209 29.4829 49.2376 29.5999 49.1542 29.7001C49.0709 29.8003 48.9543 29.8839 48.821 29.9507C48.6877 30.0175 48.5544 30.0509 48.4212 30.0509H46.9716V31.5878C46.9716 31.6881 47.005 31.7549 47.0549 31.8217C47.1049 31.8885 47.1882 31.9219 47.2549 31.9219H51.1869L50.7704 32.9744H46.5718V32.941ZM46.9716 28.9316H48.0213C48.0713 28.9316 48.1213 28.9149 48.1546 28.8815C48.1879 28.8481 48.2046 28.798 48.2046 28.7479V27.3446H46.9716V28.9316Z"
              fill="#74FFC0"
            />
            <path
              d="M54.8023 23.9868L53.7693 25.2731H54.0359V29.4662H52.5364V25.5905H51.7034L52.9863 23.9868H54.8023ZM57.2182 30.1845H61.2335V31.2704H59.7174L61.2335 33.0078H59.3508L57.9013 31.2704H57.2349V33.0078H55.7187V31.2704H55.0356L53.5861 33.0078H51.7034L53.2195 31.2704H51.7034V30.1845H55.7354V29.8504H57.2515V30.1845H57.2182ZM58.701 24.5882H61.2502V25.4903H58.7177V25.9246H61.2502V26.81H58.7177V27.2444H61.2502V28.1298H58.7177V28.5641H61.2502V29.4495H54.6524V28.5641H57.2015V28.1298H54.6524V27.2611H57.2015V26.8267H54.6524V25.9246H57.2015V25.4903H54.6524V24.5882H57.1849L57.0349 23.9868H58.5344L58.701 24.5882Z"
              fill="#74FFC0"
            />
            <path
              d="M63.0829 33.0245H61.6833V23.9701H71.2302V31.6379C71.2302 31.8384 71.1968 32.0055 71.1302 32.1725C71.0636 32.3396 70.9803 32.4899 70.8803 32.6069C70.7637 32.7405 70.647 32.824 70.4971 32.9076C70.3471 32.9744 70.1972 33.0078 70.0306 33.0078H69.2475H68.8143L63.0829 33.0245ZM63.0829 31.7883H69.2642H69.4974C69.5974 31.7883 69.6807 31.7549 69.7473 31.6714C69.814 31.5878 69.8473 31.5043 69.8473 31.3874V25.2063H63.0995V31.7883H63.0829ZM68.5477 27.3613V29.817C68.5477 30.0175 68.5144 30.1846 68.4478 30.3516C68.3811 30.5187 68.2978 30.669 68.1978 30.786C68.0812 30.9196 67.9646 31.0031 67.8146 31.0867C67.6647 31.1535 67.5147 31.1869 67.3481 31.1869H66.2652L66.7317 30.0175H66.8816C66.9816 30.0175 67.0649 29.9841 67.1315 29.9006C67.1982 29.817 67.2315 29.7335 67.2315 29.6166V27.3279H66.4651L64.8989 31.1702H63.5494L65.1155 27.3279H63.5494V26.1585H67.2315V25.5905H68.5477V26.1919H69.3808V27.3613H68.5477Z"
              fill="#74FFC0"
            />
          </svg>
        </template>

        <div v-else></div>
        <button
          class="navbar-toggler h-44px w-60px"
          :class="{ active: state }"
          type="button"
          @click="state ? toggle(false) : toggle(true)"
        >
          <div class="hamburger-container inline-block">
            <span class="icon-bar bar1" :class="{ bar1Active: state }"></span>
            <span class="icon-bar bar2" :class="{ bar2Active: state }"></span>
            <span class="icon-bar bar3" :class="{ bar3Active: state }"></span>
          </div>
        </button>
      </div>
    </van-sticky>
    <van-overlay :show="state" @click="state = false" :custom-style="{ backgroundColor: 'black' }">
      <div
        class="wrapper flex items-center px-[40px] pr-[0px] min-h-screen w-375px overflow-hidden"
        @click.stop
      >
        <nav class="text-white">
          <ul>
            <li
              v-for="navLink in navList"
              :key="navLink.name"
              class="text-[24px] leading-[28px] tracking-[1px] mb-30px text-left"
              :style="{
                color:
                  (navLink?.link !== '/' && route.path.includes(navLink?.link)) ||
                  (currentLike.includes(navLink?.link) && show && navLink?.link !== '/')
                    ? '#74FFC0'
                    : ''
              }"
              @click.stop.prevent="showChildNav(navLink)"
            >
              <span>
                {{ navLink.locale }}
              </span>
              <ul
                :class="
                  ['child-link overflow-hidden whitespace-nowrap ', navLink.childrenStyle].join('')
                "
                :Style="`height: ${
                  currentLike.includes(navLink?.link) && show ? '' : '0!important'
                }`"
                v-if="navLink.childLinks.length !== 0"
              >
                <li
                  v-for="child in navLink.childLinks"
                  :key="child.name"
                  class="text-18px leading-[20px] mb-15px !last:mb-5px text-white tracking-[.24px]"
                  :style="{
                    color: navLink?.link !== '/' && route.path === child?.link ? '#74FFC0' : ''
                  }"
                  @click.stop="linkTo(child?.link)"
                >
                  <span>{{ child.locale }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </van-overlay>
  </div>
</template>

<script setup lang="ts">
import { useToggle, useEventListener } from '@vant/use'
import router from '@/router'
const [state, toggle] = useToggle()
const show = ref(false)
const currentLike = ref('')
const route = useRoute()
const changeBg = ref(false)

const navList = [
  { name: 'Home', locale: '首页', link: '/', childLinks: [], childrenStyle: '' },
  {
    name: 'About',
    locale: '关于我们',
    childrenStyle: 'h-110px',
    link: '/about',
    childLinks: [
      {
        locale: '公司简介',
        name: 'index',
        link: '/about'
      },
      {
        locale: '核心团队',
        name: 'core-team',
        link: '/about/core-team'
      },
      {
        locale: '诺奖工作站',
        name: 'nobel',
        link: '/about/nobel'
      }
    ]
  },
  {
    name: 'Pipeline',
    locale: '研发管线',
    childrenStyle: 'h-180px',
    link: '/pipeline',
    childLinks: [
      {
        locale: '管线总览',
        name: 'index',
        link: '/pipeline'
      },
      {
        locale: 'ELPIS异体人脐带间充质干细胞注射液',
        name: 'elpis-product',
        link: '/pipeline/elpis-product'
      },
      // {
      //   locale: 'iPSC-CAR-NK',
      //   name: 'ipsc-car-nk',
      //   link: '/pipeline/ipsc-car-nk'
      // },
      {
        locale: 'iPSC-CAR-M',
        name: 'ipsc-car-m',
        link: '/pipeline/ipsc-car-m'
      },
      {
        locale: 'iPSC来源的心肌细胞贴片',
        name: 'ipsc-product',
        link: '/pipeline/ipsc-product'
      },
      {
        locale: 'iPSC来源的胰岛β细胞联合薄层包被',
        name: 'ipsc-cell-product',
        link: '/pipeline/ipsc-cell-product'
      }
    ]
  },
  {
    name: 'TecPlat',
    locale: '技术平台',
    childrenStyle: 'h-110px',
    link: '/tec-plat',
    childLinks: [
      {
        locale: 'TFiPS™诱导多能干细胞技术平台',
        name: 'index',
        link: '/tec-plat'
      },
      {
        locale: 'TruOrganoid™肿瘤类器官技术平',
        name: 'induced-stem-cell',
        link: '/tec-plat/induced-stem-cell'
      },
      {
        locale: 'CellX™生物材料整合细胞技术平台',
        name: 'integration-cell',
        link: '/tec-plat/integration-cell'
      }
    ]
  },
  {
    name: 'SncLife',
    locale: 'SNC™生命库',
    childrenStyle: 'h-50px',
    link: '/snc-life',
    childLinks: [
      {
        locale: 'SNC™生命库',
        name: 'index',
        link: '/snc-life'
      }
    ]
  },
  {
    name: 'Join',
    locale: '加入我们',
    childrenStyle: 'h-110px',
    link: '/join',
    childLinks: [
      {
        locale: '公司地址',
        name: 'index',
        link: '/join'
      },
      {
        locale: '集团新闻',
        name: 'group-news',
        link: '/join/news-1'
      },
      {
        locale: '职业发展',
        name: 'career-development',
        link: '/join/career-development'
      }
    ]
  }
]
const showChildNav = (father: any) => {
  if (father.link === '/') {
    window?.location.replace('https://www.sncstemcell.com')
  }
  if (currentLike.value === father.link) {
    show.value = !show.value
  } else {
    currentLike.value = father.link
    show.value = true
  }
}

watch(
  () => router.currentRoute.value.path,
  (toPath) => {
    //要执行的方法
    if (!currentLike.value || currentLike.value !== toPath) {
      currentLike.value = toPath
      show.value = true
    }
    toggle(false)
  },
  { immediate: true, deep: true }
)

const linkTo = (link: string) => {
  router.push(link)
  toggle(false)
}

const scrollHandle = () => {
  const top =
    window.pageYOffset || // 用于FF
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
  changeBg.value = top > 50 ? true : false
}
useEventListener('scroll', scrollHandle)
</script>

<style lang="scss" scoped>
/* Navbar toggler */
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  padding-top: 13px;
  padding-left: 18px;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: none;
}

.main-style {
  .navbar-toggler {
    @apply bg-black;
    .icon-bar {
      @apply bg-white;
    }
  }
}
.other-style {
  @apply bg-white;
  &.active {
    @apply bg-transparent;
  }
}
.navbar-toggler.active {
  background-color: #74ffc0;
}
.navbar-toggler:visited,
.navbar-toggler:focus,
.navbar-toggler:focus-within,
.navbar-toggler:active {
  border: none;
  outline: none;
}

.navbar-togger .hamburger-container {
  position: relative;
}

.navbar-toggler .icon-bar {
  position: relative;
  display: block;
  background: #000;
  width: 20px;
  height: 3px;
  margin: 4px 0;
  border-radius: 5px;
  transition: all 250ms ease-in-out;
}
.bar1Active {
  transform: translateY(7px) translateX(0) rotate(45deg);
  background: #000 !important;
}

.bar2Active {
  opacity: 0;
  transform: scale(0);
}

.bar3Active {
  transform: translateY(-7px) translateX(0) rotate(-45deg);
  background: #000 !important;
}
.child-link {
  :first-child {
    margin-top: 15px;
  }
  :last-child {
    margin-bottom: 15px;
  }
  transition: height 0.5s ease-in-out;
}
</style>
