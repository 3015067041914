<template>
  <div></div>
</template>
<script setup lang="ts">
import wxapi from '@/utils/wxapi.js'
const props = defineProps({
  pageType: {
    type: String,
    default: 'normal'
  },
  pageTitle: {
    type: String,
    default: ''
  }
})
const wxRegCallback = () => {
  console.log('wxRegCallback')
  // 用于微信JS-SDK回调
  wxShareTimeline()
  wxShareAppMessage()
}
const wxShareTimeline = () => {
  // 微信自定义分享到朋友圈
  let option = {
    title: props.pageTitle,
    pageType: props.pageType,
    success: () => {
      console.log('分享成功')
    },
    error: () => {
      console.log('取消分享')
    }
  }
  // 将配置注入通用方法
  wxapi.ShareTimeline(option)
}
const wxShareAppMessage = () => {
  // 微信自定义分享给朋友
  let option = {
    title: props.pageTitle,
    pageType: props.pageType,
    success: () => {
      console.log('分享成功')
    },
    error: () => {
      console.log('取消分享')
    }
  }
  // 将配置注入通用方法
  wxapi.ShareAppMessage(option)
}
onMounted(() => {
  wxapi.wxRegister(wxRegCallback)
})
</script>
