import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import 'virtual:windi.css'

import { createHead } from '@vueuse/head'

import 'normalize.css'
import '@/styles/index.scss'

import { useVant } from '@/plugins/vant'
import { useVconsole } from '@/utils/vconsole'
import 'vant/es/toast/style'
import 'vant/es/image-preview/style'

const app = createApp(App)

const px2rem = (px: any) => {
  if (/%/gi.test(px)) {
    // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px
  } else {
    return parseFloat(px) / 37.5 + 'rem'
  }
}
app.config.globalProperties.$px2rem = px2rem // 放到全局
app.config.globalProperties.$imgBasePath = import.meta.env.VITE_APP_OSS_API

const head = createHead()

app.use(router).use(useVant).use(head)
app.mount('#app')

declare module 'vue' {
  interface ComponentCustomProperties {
    $px2rem: (px: any) => any
    $imgBasePath: string
  }
}
;(function () {
  function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke('setFontSizeCallback&', { fontSize: 0 })
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on('menu:setfont', function () {
      WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize&': 0 })
    })
  }
  if (typeof WeixinJSBridge == 'object' && typeof WeixinJSBridge.invoke == 'function') {
    handleFontSize()
  } else {
    document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
  }
})()

useVconsole()
const images: any[] = []
function preload(array: any) {
  for (let i = 0; i < array.length; i++) {
    images[i] = new Image()
    images[i].src = array[i]
  }
}
preload([
  'https://hxy-web1.oss-cn-hangzhou.aliyuncs.com/snc-new-gw/mobile/img/about-banner.webp',
  'https://hxy-web1.oss-cn-hangzhou.aliyuncs.com/snc-new-gw/mobile/img/pipeline-banner.webp',
  'https://hxy-web1.oss-cn-hangzhou.aliyuncs.com/snc-new-gw/mobile/img/tec-plat-banner.webp',
  'https://hxy-web1.oss-cn-hangzhou.aliyuncs.com/snc-new-gw/mobile/img/snc-life-banner.webp',
  'https://hxy-web1.oss-cn-hangzhou.aliyuncs.com/snc-new-gw/mobile/img/join-banner.webp'
])

// function preventPullToRefresh(element) {
//   let prevent = false
//   document.querySelector(element).addEventListener('touchstart', function (e) {
//     if (e.touches.length !== 1) {
//       return
//     }
//     const scrollY =
//       window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
//     prevent = scrollY === 0
//   })
//   document.querySelector(element).addEventListener('touchmove', function (e) {
//     if (prevent) {
//       prevent = false
//       e.preventDefault()
//     }
//   })
// }
// preventPullToRefresh('#app') // pass #id or html tag into the method
