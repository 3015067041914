<template>
  <Layout v-if="$route.fullPath !== '/404'">
    <Suspense>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :key="route.name" v-if="$route.meta.keepAlive" />
        </keep-alive>
        <component :is="Component" :key="route.name" v-if="!$route.meta.keepAlive" />
      </router-view>
    </Suspense>
  </Layout>
  <router-view v-else />
</template>

<script setup lang="ts">
import { useHead } from '@/utils/head'
import { isPhone } from '@/utils/tools'
import { useEventListener } from '@vant/use'
const route = useRoute()
useEventListener('resize', () => {
  if (isPhone()) {
    const currentPath = route.path
    const pcPath = 'https://www.sncstemcell.com' + currentPath // 定义即将跳转的path地址
    window?.location.replace(pcPath)
  }
})

// onMounted(() => {
//   window.onresize = () => {
//     if (isPhone()) {
//       const currentPath = route.path
//       const pcPath = 'https://www.sncstemcell.com' + currentPath // 定义即将跳转的path地址
//       window?.location.replace(pcPath)
//     }
//   }
// })
onMounted(() => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})
useHead()
</script>

<style scoped></style>
