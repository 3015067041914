function searchQeury(name: string): string {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [
        '',
        ''
      ])[1].replace(/\+/g, '%20')
    ) || ''
  )
}

export function useVconsole() {
  if (searchQeury('debug')) {
    const hm = document.createElement('script')
    hm.src = 'https://cdn.staticfile.org/vConsole/3.4.1/vconsole.min.js'
    document.head.append(hm)
    hm.onload = function (evt) {
      console.log('evt: ', evt)
      new window.VConsole()
    }
  }
}
