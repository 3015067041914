import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { isPhone } from '@/utils/tools'
import NewsDetail from '@/views/join/detail.vue'
import GroupNews from '@/views/join/news.vue'
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'app',
    meta: {
      title: 'app'
    },
    redirect: '/about',
    children: [
      // 需要layout的页面
      {
        path: 'index',
        name: 'index',
        meta: {
          title: 'index'
        },
        component: () => import('@/views/index.vue')
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          title: 'about'
        },
        component: () => import('@/views/about/index.vue')
      },
      {
        path: '/about/core-team',
        name: 'CoreTeam',
        meta: {
          keepAlive: true
        },
        component: () => import('@/views/about/core-team.vue')
      },
      {
        path: '/about/nobel',
        name: 'Nobel',
        meta: {
          keepAlive: true
        },
        component: () => import('@/views/about/nobel.vue')
      },
      {
        path: '/pipeline',
        name: 'Pipeline',
        meta: {
          title: 'pipeline'
        },
        component: () => import('@/views/pipeline/index.vue')
      },
      {
        path: '/pipeline/elpis-product',
        name: 'PipelineProduct',
        meta: {
          title: 'pipeline'
        },
        component: () => import('@/views/pipeline/elpis-product.vue')
      },
      {
        path: '/pipeline/ipsc-car-nk',
        name: 'IpscCarNK',
        meta: {
          title: 'pipeline',
          keepAlive: true
        },
        component: () => import('@/views/pipeline/ipsc-car-nk.vue')
      },
      {
        path: '/pipeline/ipsc-car-m',
        name: 'IpscCarM',
        meta: {
          title: 'pipeline',
          keepAlive: true
        },
        component: () => import('@/views/pipeline/ipsc-car-m.vue')
      },
      {
        path: '/pipeline/ipsc-product',
        name: 'IpscProduct',
        meta: {
          title: 'pipeline',
          keepAlive: true
        },
        component: () => import('@/views/pipeline/ipsc-product.vue')
      },
      {
        path: '/pipeline/ipsc-cell-product',
        name: 'IpscCellProduct',
        meta: {
          title: 'pipeline',
          keepAlive: true
        },
        component: () => import('@/views/pipeline/ipsc-cell-product.vue')
      },
      {
        path: '/tec-plat',
        name: 'TecPlat',
        meta: {
          title: 'TecPlat'
        },
        component: () => import('@/views/tec-plat/index.vue')
      },
      {
        path: '/tec-plat/induced-stem-cell',
        name: 'TruOrganoid™肿瘤类器官技术平',
        meta: {
          title: 'induced-stem-cell',
          keepAlive: true
        },
        component: () => import('@/views/tec-plat/induced-stem-cell.vue')
      },
      {
        path: '/tec-plat/integration-cell',
        name: 'CellX™生物材料整合细胞技术平台',
        meta: {
          title: 'integration-cell',
          keepAlive: true
        },
        component: () => import('@/views/tec-plat/integration-cell.vue')
      },
      {
        path: '/snc-life',
        name: 'SncLife',
        meta: {
          title: 'SncLife'
        },
        component: () => import('@/views/snc-life/index.vue')
      },
      {
        path: '/join',
        name: 'Join',
        meta: {
          title: 'Join'
        },
        component: () => import('@/views/join/index.vue')
      },
      {
        path: '/join/news-1',
        name: 'group-news',
        meta: {
          title: 'group-news',
          keepAlive: true
        },
        component: GroupNews
      },
      {
        path: '/join/news-:pageId/:newsId',
        name: 'news-detail',
        meta: {
          title: 'news-detail'
        },
        component: NewsDetail
      },
      {
        path: '/join/career-development',
        name: 'career-development',
        meta: {
          title: 'career-development',
          keepAlive: true
        },
        component: () => import('@/views/join/career-development.vue')
      }
    ]
  },
  // 替代vue2中的'*'通配符路径
  { path: '/404', component: () => import('@/views/404.vue') },
  { path: '/:pathMatch(.*)*', redirect: '/404' }
]

const router = createRouter({
  history: createWebHashHistory(), // history 模式则使用 createWebHistory()
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.title === 'group-news') {
      return savedPosition ? savedPosition : { left: 0, top: 0 }
    } else {
      // 异步滚动操作
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ top: to.meta.savedPosition ? (to.meta.savedPosition as number) : 0 })
        }, 0)
      })
    }
  }
})

router.beforeEach((to, from, next) => {
  // 判断设备切换PC或移动端
  if (isPhone()) {
    const currentPath = router.options.history.state.current
    const pcPath = 'https://www.sncstemcell.com' + currentPath // 定义即将跳转的path地址
    window?.location.replace(pcPath)
  }
  next()
})

export default router
