<template>
  <div class="pt-44px flex flex-col min-h-screen">
    <!-- 中段标题 -->
    <PageTitle :main-title="aboutMainCon" />

    <!-- 主内容 -->
    <div class="bg-white p-15px h-full flex-1">
      <van-skeleton :loading="loading" animate>
        <template #template>
          <div :style="{ height: '100%', width: '100%', textAlign: 'center' }">
            <van-skeleton-title class="inline-block w-4/5" />
            <van-skeleton-title class="inline-block" />
            <div :style="{ marginTop: '26px' }">
              <van-skeleton-paragraph v-for="item in 18" :key="item" />
            </div>
          </div>
        </template>
        <!-- 主标题 -->
        <div class="text-[22px] font-semibold leading-[30px] text-center">
          {{ articleDetail?.title }}
        </div>
        <!-- 副标题 -->
        <div
          class="text-[14px] mt-[7px] mb-[40px] text-[rgba(33, 39, 35, 1)] flex justify-center opacity-40 flex-wrap"
        >
          <div class="mr-12px mb-5px whitespace-nowrap">
            日期： {{ parseTime(articleDetail?.createdDate ?? 0, '{y}/{m}/{d}') }}
          </div>
          <div class="mr-12px whitespace-nowrap">浏览次数：{{ articleDetail?.views }}</div>
          <div class="whitespace-nowrap" v-if="articleDetail?.articleSource">
            来源：{{ articleDetail.articleSource }}
          </div>
        </div>
        <!-- 正文 -->
        <div
          class="text-[16px] leading-[18px] tracking-[0px] w-full overflow-hidden"
          v-html="articleDetail?.texts ?? '无'"
        ></div>
        <!-- 前一页后一页 -->
        <div class="text-[14px] leading-[18px] mt-[81px]">
          <div
            v-if="articleDetail?.previousPage?.id"
            @click="goToArticleDetail(articleDetail?.previousPage?.id as number)"
            class="text-[#212723] mb-[18px] cursor-pointer opacity-40 block"
          >
            上一篇：{{ articleDetail?.previousPage?.title ?? '无' }}
          </div>
          <div
            v-if="articleDetail?.nextPage?.id"
            @click="goToArticleDetail(articleDetail?.nextPage?.id as number)"
            class="text-[#18A565] cursor-pointer pb-75px block"
          >
            下一篇：{{ articleDetail.nextPage?.title ?? '无' }}
          </div>
        </div>
      </van-skeleton>
    </div>
    <WxConfig pageType="normal" :pageTitle="articleDetail?.title" />
  </div>
</template>

<script setup lang="ts">
import Article from '@/api/Article'
import { parseTime, sleep } from '@/utils/index'
import { ArticleDetailType } from '@/types/index'
import { scrollTo1 } from '@/utils/scroll-to'
import { showFailToast } from 'vant'
import { useHead } from '@/utils/head'

const loading = ref(false)
// 中段标题
const aboutMainCon = {
  title: '集团新闻',
  titleStyle: '',
  descImg: `${import.meta.env.VITE_APP_OSS_API}/img/join-main-title.png`
}
const route = useRoute()
const router = useRouter()
const articleDetail = ref<ArticleDetailType>()

const getArticleDetail = async () => {
  if (!route.params.newsId) {
    return
  }
  try {
    loading.value = true
    const { code, data } = await Article.getArticleDetail({
      id: route.params.newsId,
      random: Math.random()
    })
    await sleep(500)
    if (code === 200) {
      articleDetail.value = data
      useHead({
        title: `${articleDetail.value?.title}_华夏源细胞集团`,
        meta: [
          {
            name: 'description',
            content: articleDetail.value?.description as string
          },
          {
            name: 'keywords',
            content: articleDetail.value?.keywords as string
          }
        ]
      })
    }
  } catch (error) {
    //@ts-ignore
    showFailToast(error.message)
  } finally {
    loading.value = false
  }
}
getArticleDetail()
const goToArticleDetail = (id: number) => {
  router.push({ path: `/join/news-1/${id}` })
}
watch(
  () => router.currentRoute.value.path,
  (toPath) => {
    //要执行的方法
    console.log(toPath)
    if (toPath === '/join/news-1') {
      return
    }
    getArticleDetail()
    scrollTo1(0)
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped></style>
