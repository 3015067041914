// sleep
export const sleep = (t = 1000, err?: any): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (err) reject(err)
      else resolve(1)
    }, t)
  })
}

// 搜索链接中的参数
export function searchQeury(name: string): string {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [
        '',
        ''
      ])[1].replace(/\+/g, '%20')
    ) || ''
  )
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url: string) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj: any = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

export function filterQuery(query: any, ...keys: string[]) {
  return Object.entries(query).reduce((acc: any, [k, v]) => {
    if (!keys.includes(k)) acc[k] = v
    return acc
  }, {})
  // delete query[key]
  // return query
}
// 删除url中的query参数
export function delUrlQury(url = location.href, ...keys: string[]) {
  const query = filterQuery(getQueryObject(url), ...keys)
  const queryStr = Object.entries(query)
    .map((it) => it.join('='))
    .join('&')
  let base = url.split('?')[0]
  if (queryStr) base += '?'
  return base + queryStr
}

// 判断ios
export function isIos() {
  return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

/**
 * [iswx 判断是否微信浏览器]
 */
export function iswx() {
  const ua = window.navigator.userAgent.toLowerCase()
  return !!ua.match(/MicroMessenger/i)
}

// 校验URL是否正常
export function validateUrl(target: string) {
  return /(http|https):\/\/([\w.]+\/?)\S*/.test(target)
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time: Date | number | string, cFormat?: string) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date: Date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  type formatObjKey = 'y' | 'm' | 'd' | 'h' | 'i' | 's' | 'a'
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key: formatObjKey) => {
    let value: any = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

//预加载图片
const images: any[] = []
export function preload(array: any) {
  for (let i = 0; i < array.length; i++) {
    images[i] = new Image()
    images[i].src = array[i]
  }
}
