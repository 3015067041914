<template>
  <div class="pt-44px">
    <!-- 中段标题 -->
    <PageTitle :main-title="aboutMainCon" />
    <!-- 主内容 -->
    <div class="bg-[#fff]">
      <div class="h-166px w-screen pl-15px mb-32px">
        <swiper
          :slidesPerView="1.2"
          :spaceBetween="15"
          :pagination="{
            clickable: true
          }"
          class="mySwiper h-full"
        >
          <swiper-slide
            v-for="(item, index) in articleTop"
            :key="index"
            :class="['h-full object-cover relative '].join('')"
            @click.stop="goToArticleDetail(item.id)"
          >
            <div class="h-166px relative overflow-hidden">
              <img class="h-full w-full object-cover" :src="item.coverimage" alt="" />
              <img
                class="absolute w-full h-full top-0 object-cover"
                :src="`${$imgBasePath}/img/news-item-filter.png`"
                alt=""
              />
            </div>
            <div
              class="news-item two-line-overflow absolute h-36px bottom-10px px-8px text-[16px] text-[#fff] leading-[1.18em]"
            >
              <p v-html="item.description"></p>
            </div>
            <div
              class="absolute top-0 right-0 text-[13px] leading-[18px] px-4px py-3px text-[#fff] bg-[#18A565]"
            >
              {{ parseTime(item.createdDate, '{y}/{m}/{d}') }}
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="px-16px">
        <van-list
          v-model:loading="state.loading"
          :finished="state.finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="relative flex justify-between items-center mb-30px"
            v-for="card in state.list"
            :key="card.id"
            @click="goToArticleDetail(card.id)"
          >
            <div class="">
              <p
                class="w-222px h-56px three-line-overflow text-[16px] leading-[1.175em] font-medium mb-5px"
              >
                {{ card.title }}
              </p>
              <div class="flex items-center">
                <img
                  class="w-58px h-18px mr-11px"
                  :src="`${$imgBasePath}/img/news-item-created.png`"
                  alt=""
                />
                <p class="text-[12px] opacity-30">
                  {{ parseTime(card.createdDate, '{y}/{m}/{d}') }}
                </p>
              </div>
            </div>
            <img
              class="w-118px h-70px object-cover"
              :src="card.coverimage"
              :alt="card.browserTitle"
            />
            <div
              v-if="state.list ?? [].length > 1"
              class="absolute w-343px h-1px bg-black opacity-20 top-87px"
            ></div>
          </div>
        </van-list>
      </div>
    </div>
    <WxConfig pageType="normal" :pageTitle="'集团新闻'" />
  </div>
</template>

<script setup lang="ts">
import Article from '@/api/Article'
import { parseTime, sleep } from '@/utils/index'
import { ArticleType } from '@/types/index'

import { showFailToast } from 'vant'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'
import { useHead } from '@/utils/head'

import { useLoading } from '@/hooks/useLoading'

const { startLoading, stopLoading } = useLoading()
const router = useRouter()
// 中段标题
const aboutMainCon = {
  title: '集团新闻',
  titleStyle: '',
  descImg: `${import.meta.env.VITE_APP_OSS_API}/img/join-main-title.png`
}

const articleTop = ref<Array<ArticleType>>([])

const login = async () => {
  startLoading()
  try {
    const { code, data } = await Article.getArticleTop()
    await sleep(500)
    if (code === 200) {
      articleTop.value = data
      stopLoading()
    }
  } catch (error) {
    //@ts-ignore
    showFailToast(error.message)
  } finally {
    stopLoading()
  }
}
login()

const goToArticleDetail = (id: number) => {
  router.push(`/join/news-1/${id}`)
}

interface StateType {
  loading?: boolean
  finished?: boolean
  error?: boolean
  list?: ArticleType[] | null
  page?: number | null
  size?: number
  total?: number
}
// new-list
const state: StateType = reactive({
  loading: false,
  finished: false,
  error: false,
  list: [],
  page: 1,
  size: 10,
  total: 0
})

const getList = async () => {
  try {
    if (state.page === 1) {
      state.list = []
    }
    let res = await Article.getArticleList({
      page: state.page ?? 1,
      size: state.size ?? 10,
      random: Math.random()
    })
    const data: ArticleType[] = res.data
    console.log(res)

    if (data.length === 0) {
      state.list = []
      state.finished = true
    }

    state.total = res.pagination.total
    ;(state.list as any).push(...data)
    state.loading = false

    if ((state.list as any).length >= res.pagination.total) {
      state.finished = true
    }
  } catch (err) {
    //@ts-ignore
    showFailToast(err.message)
  }
}

const onLoad = async () => {
  let timer = setTimeout(() => {
    getList()
    ;(state as any).page++
    state.finished && clearTimeout(timer)
  }, 1000)
}
useHead({ title: '集团新闻_华夏源细胞集团' })
</script>

<style lang="scss" scoped></style>
