import { App } from 'vue'
import { Loading, Button, Cell, CellGroup, Image, Icon, Tab, Tabs, Toast, List, Sticky } from 'vant'

const components = [Button, Loading, Cell, CellGroup, Image, Icon, Tab, Tabs, Toast, List, Sticky]

export function useVant(app: App) {
  components.forEach((el) => {
    app.use(el)
  })
}
