<template>
  <div
    class="flex flex-col items-center footer bg-white text-center text-10px h-89px flex flex-col justify-center absolute w-screen bottom-0"
  >
    <img class="object-cover w-229px mb-4px" :src="`${$imgBasePath}/img/gw-bottom-2.png`" alt="" />
    <img class="object-cover w-93px" :src="`${$imgBasePath}/img/gw-bottom-3.png`" alt="" />
    <div class="flex justify-center items-center">
      <a
        class="inline-block h-[22px] flex justify-center items-center underline"
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202009862"
        data-v-77231f33=""
        ><img
          class="inline-block mr-6px w-20px h-20px"
          style="vertical-align: baseline"
          src="https://hxy-web1.oss-cn-hangzhou.aliyuncs.com/snc-new-gw/img/police.png"
          alt=""
          data-v-77231f33=""
        />
        <img class="object-cover w-142px" :src="`${$imgBasePath}/img/gw-bottom-4.png`" alt="" />
      </a>
      <a
        class="inline-block h-[22px] leading-[22px] ml-6px underline flex items-center"
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        data-v-77231f33=""
      >
        <img class="object-cover w-106px" :src="`${$imgBasePath}/img/gw-bottom-5.png`" alt="" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
