import { showLoadingToast } from 'vant'

export function useLoading() {
  let toast: any = null

  const startLoading = () => {
    toast = showLoadingToast({
      duration: 0,
      forbidClick: true,
      message: '加载中...'
    })
  }
  const stopLoading = () => {
    console.log(toast)

    toast && toast.close()
  }

  onBeforeUnmount(stopLoading)

  return { startLoading, stopLoading }
}
