export const isPhone = () => {
  const flag = navigator.userAgent.match(
    // match方法可在字符串内检索指定的值，
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )

  if (flag) {
    return false
  } else {
    return true
  }
}

/**
 * [iswx 判断是否微信浏览器]
 */
export function iswx() {
  const ua = window.navigator.userAgent.toLowerCase()
  return !!ua.match(/MicroMessenger/i)
}
