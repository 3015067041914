import axios from 'axios'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: 'https://share.sncstemcell.com', // api 的 base_url
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // if (store.getters.token) {
    //   config.headers['authorization'] = getToken()
    // }
    return config
  },
  (error) => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    return response
  },
  /* response => {
    const res = response.data
    if (res.code !== '200') {
      console.log('[err-'+res.code+']:',res)
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.code === '403') {
        // 请自行在引入 MessageBox
        // import { Message, MessageBox } from 'element-ui'
        
      }
      return Promise.reject('error')
    } else {
      return response.data
    }
  }, */
  (error) => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
