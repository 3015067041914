/**
 * 微信js-sdk
 * 参考文档：https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
 */

import wx from 'weixin-js-sdk'
import { getWxConfig } from '@/api/getWxInfo'

const jsApiList = [
  'onMenuShareTimeline',
  'onMenuShareAppMessage',
  'updateAppMessageShareData',
  'updateTimelineShareData'
]
const shareImage_LOGO =
  'https://hxy-web1.oss-cn-hangzhou.aliyuncs.com/snc-new-gw/mobile/logo-wx-1.png'
const shareDesc = '推动细胞科技惠及普罗大众'

function setShareTitleRole(pageType = 'normal', title) {
  let returnTitle = ''
  switch (pageType) {
    case 'normal':
      {
        returnTitle = title + '_华夏源细胞集团'
      }
      break
    case 'home':
      {
        returnTitle = '华夏源细胞集团'
      }
      break
    case 'page':
      {
        returnTitle = title
      }
      break
    default: {
    }
  }
  return returnTitle
}

const wxApi = {
  /**
   * [wxRegister 微信Api初始化]
   * @param  {Function} callback [ready回调函数]
   */
  wxRegister(callback) {
    if (!this.isweixin()) return

    getWxConfig(location.href)
      .then((res) => {
        let data = res.data // PS: 这里根据你接口的返回值来使用
        wx.config({
          debug: false, // 开启调试模式
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timeStamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名，见附录1
          jsApiList: jsApiList // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.ready(function (res) {
          console.log('ready', res)
          // 如果需要定制ready回调方法
          if (callback) {
            callback()
          }
        })
        wx.error(function (err) {
          console.log('err:', err)
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        })
      })
      .catch((err) => {})
  },
  /**
   * [ShareTimeline 微信分享到朋友圈]
   * @param {[type]} option [分享信息]
   * @param {[type]} success [成功回调]
   * @param {[type]} error   [失败回调]
   */
  ShareTimeline(option) {
    wx.updateTimelineShareData({
      title: document.title,
      link: window.location.href, // 分享链接
      imgUrl: shareImage_LOGO, // 分享图标
      success() {
        // 用户成功分享后执行的回调函数
        option.success()
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        option.error()
      }
    })
  },
  /**
   * [ShareAppMessage 微信分享给朋友]
   * @param {[type]} option [分享信息]
   * @param {[type]} success [成功回调]
   * @param {[type]} error   [失败回调]
   */
  ShareAppMessage(option) {
    wx.updateAppMessageShareData({
      title: setShareTitleRole(option.pageType, option.title),
      link: window.location.href, // 分享链接
      imgUrl: shareImage_LOGO, // 分享图标
      desc: shareDesc, // 分享描述, 请自行替换
      success() {
        // 用户成功分享后执行的回调函数
        option.success()
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        option.error()
      }
    })
  },

  /**
   * [isweixin 判断是否微信浏览器]
   */
  isweixin() {
    const ua = window.navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true
    } else {
      return false
    }
  }
}
export default wxApi
