import http, { Response } from '@/utils/http'
import { ArticleType } from '@/types/index'

export interface ListParams {
  page: number
  size: number
  random: number
}
interface paginationType {
  total?: number
  page?: number
  size?: number
}
interface Article {
  code: number
  data: Array<ArticleType>
  message: string
  pagination: paginationType
}

export default {
  async getArticleList({ page, size, random }: ListParams) {
    return await http.get<Response<Article>>(
      `/sncstemcell/query?page=${page}&size=${size}&isTop=0`,
      { random }
    )
  },
  async getArticleTop() {
    return await http.get<Response<Article>>('/sncstemcell/query?isTop=1')
  },
  async getArticleDetail({ id, random }: any) {
    return await http.get<Response<ArticleType>>(`/sncstemcell/find/${id}`, { random })
  }
}
